<template>
  <!-- Page Section Start -->
  <div class="page-section section section-padding section-space--inner--60">
    <div class="container">
      <div class="row row-30 mb-1">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home ></a></li>
            <li class="breadcrumb-item">
              <a :href="/product-category/ + this.$route.params.jenis_produk"
                >{{ this.$route.params.jenis_produk }} ></a
              >
            </li>
            <li class="breadcrumb-item" aria-current="page">
              <a
                :href="
                  /product/ +
                  this.$route.params.jenis_produk +
                  /merk-produk/ +
                  this.$route.params.merk_produk
                "
                >{{ this.$route.params.merk_produk }} ></a
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ this.$route.params.nama_produk }}
            </li>
          </ol>
        </nav>
      </div>
      <div class="row row-30 section-space--bottom--40" style="margin: 0px">
        <div class="col-12">
          <div
            class="row"
            v-for="produk in produks"
            v-bind:key="produk.id_produk"
          >
            <div
              class="col-lg-5 col-12 section-space--bottom--40 text-center detil-img-size"
              style="padding-right: 50px; overflow: hidden;"
            >
              <img
                :src="produk.url_gambar_produk"
                class="img-fluid"
                :alt="produk.nama_produk"
              />
            </div>

            <div class="col-lg-7 col-12">
              <div class="single-product-content">
                <div class="row section-space--bottom--20">
                  <div class="head">
                    <div class="head-left">
                      <h3 class="title">{{ produk.nama_produk }}</h3>
                      <p>{{ produk.merk_produk }}</p>
                    </div>
                  </div>
                </div>

                <div class="row section-space--bottom--20">
                  <!-- Nav tabs -->
                  <div>
                    <b-tabs content-class="mt-3 mb-5">
                      <b-tab title="Deskripsi" active
                        ><p v-html="produk.deskripsi_produk"></p
                      ></b-tab>

                      <b-tab title="Harga Produk">
                        <b-table
                          responsive
                          fixed
                          :fields="fields"
                          :items="detilproduks"
                          style="max-height: 300px; overflow: auto"
                        >
                        </b-table>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
                <div class="row section-space--bottom--20">
                  <p>Informasi dan Pemesanan</p>
                </div>
                <div class="row">
                  <div class="col-md-4 col-6 mb-3">
                    <div class="actions" style="color: #25d366">
                      <button class="wa-button">
                        <a href="https://wa.me/6285853527441" target="_blank">
                          <i class="fa fa-whatsapp"></i>
                          <span>WhatsApp</span>
                        </a>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-4 col-6">
                    <div class="actions">
                      <button class="linktree-button">
                        <a
                          href="https://linktr.ee/cvputraramayana"
                          target="_blank"
                        >
                          <i class="fa fa-link"></i>
                          <span>Linktree</span>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Section End -->
</template>

<script>
import axios from "axios";
export default {
  name: "app",
  data() {
    return {
      key: 0,
      produks: [],
      detilproduks: [],
      fields: [
        {
          key: "ukuran_produk",
          sortable: true,
          label: "Ukuran Produk",
        },
        {
          key: "tipe_produk",
          sortable: true,
          label: "Tipe Produk",
        },
        {
          key: "harga_produk",
          sortable: true,
          label: "Price List Produk",
          formatter: (value, key, item) => {
            let formatter = new Intl.NumberFormat("id", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
            });
            return formatter.format(value);
          },
        },
      ],
    };
  },

  metaInfo() {
    return {
      title:
        this.$route.params.jenis_produk +
        " " +
        this.$route.params.merk_produk +
        " " +
        this.$route.params.nama_produk +
        " - CV Putra Ramayana Grosir Ban, Oli, Aki, dan Sparepart Solo Murah",
      meta: [
        {
          name: "keywords",
          content: "CV Putra Ramayana Solo",
        },
        {
          property: "og:keyword",
          content: "CV Putra Ramayana",
        },
        {
          name: "description",
          content:
            "Grosir Ban, Oli, Aki, dan Sparepart Solo Terlengkap dan Termurah - " +
            this.$route.params.jenis_produk +
            " " +
            this.$route.params.merk_produk +
            " " +
            this.$route.params.nama_produk,
        },
        {
          property: "og:title",
          content:
            this.$route.params.jenis_produk +
            " " +
            this.$route.params.merk_produk +
            " " +
            this.$route.params.nama_produk +
            " - CV Putra Ramayana " +
            "Grosir Ban Oli Aki Sparepart Solo Jawa Tengah Murah",
        },
        {
          property: "og:site_name",
          content: "www.putraramayanasolo.com" + this.$route.fullPath,
        },
        {
          property: "og:viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          property: "og:viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getData() {
      axios
        .get(
          "https://admin.putraramayana.com/api/produks/getbyid/" +
            this.$route.params.id_produk
        )
        .then((response) => {
          this.produks = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getHargaData() {
      axios
        .get(
          "https://admin.putraramayana.com/api/produks/gethargabynama/" +
            this.$route.params.nama_produk
        )
        .then((response) => {
          this.detilproduks = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  mounted() {
    this.getData();
    this.getHargaData();
    this.key++;
  },
};
</script>

<style></style>
