<template>
  <div class="main-container">
    <Header />
    <DetilProduct />
    <Footer />
    <OffCanvasMobileMenu />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import DetilProduct from "../components/DetilProduct.vue";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

export default {
  name: "App",
  components: {
    Header,
    DetilProduct,
    Footer,
    OffCanvasMobileMenu,
  },
  // metaInfo: {
  //   title: "Putra Ramayana - Detail Produk",
  //   htmlAttrs: {
  //     lang: "en",
  //     amp: true,
  //   },
  // },
};
</script>
